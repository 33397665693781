<template>
  <v-app>
    <!--pages-core-app-bar /-->

    <router-view class="ma-auto pa-0" />

  </v-app>
</template>

<script>
  export default {
    name: 'PagesIndex',

    components: {

    },
  }
</script>
